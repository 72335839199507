// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, SVG, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {lv8VcIJzy: {hover: true}};

const cycleOrder = ["lv8VcIJzy"];

const variantClassNames = {lv8VcIJzy: "framer-v-tl5kwk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {"lv8VcIJzy-hover": {damping: 74, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 232, type: "spring"}, default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "lv8VcIJzy", tap: d8YjthUDn, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lv8VcIJzy", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1enagsp = activeVariantCallback(async (...args) => {
if (d8YjthUDn) {
const res = await d8YjthUDn(...args);
if (res === false) return false;
}
})

const isDisplayed = () => {
if (gestureVariant === "lv8VcIJzy-hover") return true
return false
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sESDs", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-tl5kwk", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"lv8VcIJzy"} onTap={onTap1enagsp} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"lv8VcIJzy-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-yds607"} layoutDependency={layoutDependency} layoutId={"fbPbehQck"} transition={transition}><SVG className={"framer-15dlp5q"} data-framer-name={"🦆 icon \"home\""} fill={"rgba(0,0,0,1)"} intrinsicHeight={116} intrinsicWidth={133} layoutDependency={layoutDependency} layoutId={"s3B4UAmFE"} svg={"<svg width=\"133\" height=\"116\" viewBox=\"0 0 133 116\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M66.2484 0L0 49.6863H16.5621V115.935H49.6863V82.8104H82.8104V115.935H115.935V49.1894L132.497 49.6863L66.2484 0Z\" fill=\"white\"/>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TW9udHNlcnJhdC04MDA=", "--framer-font-family": "\"Montserrat\", serif", "--framer-font-weight": "800", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Kivitelezésig</motion.p></React.Fragment>} className={"framer-dr5ght"} fonts={["GF;Montserrat-800"]} layoutDependency={layoutDependency} layoutId={"JjB31kiXh"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} verticalAlignment={"center"} withExternalLayout/>)}</motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sESDs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sESDs * { box-sizing: border-box; }", ".framer-sESDs .framer-1rfbupp { display: block; }", ".framer-sESDs .framer-tl5kwk { cursor: pointer; height: 35px; overflow: hidden; position: relative; width: 41px; }", ".framer-sESDs .framer-yds607 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-sESDs .framer-15dlp5q { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-sESDs .framer-dr5ght { flex: none; height: 34px; left: 15px; position: absolute; top: 68px; white-space: pre-wrap; width: 132px; word-break: break-word; word-wrap: break-word; }", ".framer-sESDs .framer-v-tl5kwk .framer-tl5kwk { cursor: pointer; }", ".framer-sESDs.framer-v-tl5kwk.hover .framer-tl5kwk { height: 127px; width: 165px; }", ".framer-sESDs.framer-v-tl5kwk.hover .framer-yds607 { bottom: unset; height: 35px; left: calc(49.69696969696972% - 41px / 2); right: unset; top: 10px; width: 41px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 41
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"F4fsoN0LJ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"d8YjthUDn":"tap"}
 */
const FramerJhwsADZdQ: React.ComponentType<Props> = withCSS(Component, css, "framer-sESDs") as typeof Component;
export default FramerJhwsADZdQ;

FramerJhwsADZdQ.displayName = "Hero icons";

FramerJhwsADZdQ.defaultProps = {height: 35, width: 41};

addPropertyControls(FramerJhwsADZdQ, {d8YjthUDn: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerJhwsADZdQ, [{family: "Montserrat", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/JhwsADZdQ:default", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-Y3tcoqK5.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-Y3tcoqK5.ttf", weight: "800"}])